import { Controller } from "@hotwired/stimulus"

import { MarkerClusterer } from "@googlemaps/markerclusterer";

export default class extends Controller {
    static targets = ["map", "count"]
    static values = { latitude: Number, longitude: Number, zoom: Number, geojsonurl: String }

    connect() {
        if (typeof (google) != "undefined") {
            this.initMap()
            console.log("Downtown Lafayette Controller Connected....")
        }
    }

    async initMap() {
        const { Map } = await google.maps.importLibrary("maps");

        this.map = new Map(this.mapTarget, {
            center: new google.maps.LatLng(this.latitudeValue || 39.5, this.longitudeValue || -98.35),
            zoom: (this.zoomValue || 6),
            controlSize: 24,
            styles: [
                {
                    featureType: 'poi.business',
                    stylers: [{ visibility: 'off' }]
                }
            ]
        })

        this.map.data.setStyle((feature) => {
            let fillColor = "gray"
            let fillOpacity = 0.0
            let strokeColor = "black"
            let strokeWeight = 1.5;
            let strokeOpacity = 0.6

            if (feature.getProperty("stroke")) {
                strokeColor = feature.getProperty("stroke");
            }

            if (feature.getProperty("stroke-width")) {
                strokeWeight = feature.getProperty("stroke-width");
            }

            if (feature.getProperty("stroke-opacity")) {
                strokeOpacity = feature.getProperty("stroke-opacity");
            }

            if (feature.getProperty("fill-color")) {
                fillColor = feature.getProperty("fill-color");
            }

            if (feature.getProperty("fill-opacity")) {
                fillOpacity = feature.getProperty("fill-opacity");
            }

            return /** @type {!google.maps.Data.StyleOptions} */ {
                fillColor: fillColor,
                fillOpacity: fillOpacity,
                strokeColor: strokeColor,
                strokeWeight: strokeWeight,
                strokeOpacity: strokeOpacity
            };
        });

        console.log(this.geojsonurlValue)

        this.map.data.loadGeoJson(this.geojsonurlValue);

        let url = "/downtown_lafayette_list"

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(out => {
                // "id": "bec349a9-5a84-4aa2-9d83-4916df0195c1",
                // "name": "Apple Annie's Bake Shop",
                // "street_address_1": "837 South Kerr Avenue",
                // "street_address_2": "",
                // "city_string": "Wilmington",
                // "state": "NC",
                // "zip_code_string": "",
                // "latitude": "34.221916",
                // "longitude": "-77.885741",
                // "zoom_level": 6,
                // "url": "/nexus/organizations/ca5ea6ad-572a-4130-82ba-3d84efb6d348"

                var items = out

                const map = this.map

                this.countTarget.innerHTML = items.length

                const markers = items.map((item, i) => {

                    const svgMarker = {
                        path: item.map_icon.svg_path,
                        fillColor: item.map_icon.fill_color,
                        fillOpacity: 1,
                        strokeWeight: 1,
                        strokeColor: item.map_icon.stroke_color,
                        rotation: 0,
                        scale: 0.6,
                        labelOrigin: new google.maps.Point(0, -30),
                        anchor: new google.maps.Point(0, 0),
                    };

                    const location = { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) };

                    const marker = new google.maps.Marker({
                        position: location,
                        icon: svgMarker,
                        zIndex: item.map_icon.z_index
                    });

                    const contentString =
                        '<div><a href="' + item.url +'" class="btn btn-dark btn-sm btn-block">' + item.name + '</a></div>' +
                        '<div style="margin-top: 6px">' + item.street_address_1 + '</div>' +
                        '<div>' + item.street_address_2 + '</div>' +
                        '<div>' + item.city_string + ', ' + item.state + '</div>'

                    const infowindow = new google.maps.InfoWindow({
                        content: contentString,
                    });

                    marker.addListener("click", () => {
                        infowindow.open({
                            anchor: marker,
                            map,
                            shouldFocus: false,
                        });

                        google.maps.event.addListener(map, "click", function(event) {
                            infowindow.close();
                        });
                    });

                    marker.setMap(this.map);

                    // return marker;
                });

                // const clusterOptions = {
                //     gridSize: 30,
                //     maxZoom: 10,
                //     minimumClusterSize: 5
                // };
                //
                // new MarkerClusterer({ map, markers, clusterOptions });
            })
    }
}