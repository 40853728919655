import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "field",
        "placeData",
        "selectedPlace",
        "address",
        "url",
        "rowID",
        "googleSheetUrl",
        "googleSheetName",
        "existingOrganizations",
        "organizationSearchField",
        "directTitleField",
        "directURLField",
        "directOverviewField"
    ]

    connect() {
        if (typeof(google) != "undefined") {
            this.initMap()
        }
    }

    initMap() {
        this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
        this.autocomplete.setFields(['address_components','geometry', 'name', 'formatted_address', 'utc_offset_minutes', 'website', 'place_id', 'formatted_phone_number', 'business_status'])
        this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
        this.rowIDTarget.value = localStorage.getItem("rowID")
        this.googleSheetNameTarget.innerHTML = localStorage.getItem("googleSheetName")
        this.googleSheetUrlTarget.innerHTML = localStorage.getItem("googleSheetUrl")

        let googleSheetName = localStorage.getItem("googleSheetName")

        const params = new URLSearchParams(window.location.search)
        if (params.has('organization_name')) {
            console.log(params.get('organization_name'))
            this.organizationNameSearch(params.get('organization_name'))
        } else if (googleSheetName) {
            this.organizationNameSearch(googleSheetName.substring(0,5))
        }
    }

    placeChanged() {
        let place = this.autocomplete.getPlace()

        if (!place.geometry || !place.geometry.location) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            window.alert("No details available for input: '" + place.name + "'");
            return;
        }
        this.placeDataTarget.value = JSON.stringify(place)
        this.selectedPlaceTarget.value = place.website
        this.addressTarget.innerHTML = place.formatted_address
        this.urlTarget.innerHTML = place.website
        this.organizationNameSearch(place.name.substring(0,5))
    }

    preview(event) {
        if (this.selectedPlaceTarget.value) {
            window.open(this.selectedPlaceTarget.value, '_blank').focus();
        } else {
            window.alert("There is no website currently available.")
        }
    }

    previewRowLink(event) {
        if (this.googleSheetUrlTarget.innerHTML) {
            window.open(this.googleSheetUrlTarget.innerHTML, '_blank').focus();
        } else {
            window.alert("There is no website currently available.")
        }
    }

    clearDisplayData() {
        this.placeDataTarget.value = null
        this.selectedPlaceTarget.value = null
        this.addressTarget.innerHTML = "&nbsp;"
        this.urlTarget.innerHTML = "&nbsp;"
        this.googleSheetNameTarget.innerHTML = "&nbsp;"
        this.googleSheetUrlTarget.innerHTML = "&nbsp;"
    }

    createOrganizationDirect() {
        this.createOrganizationManually(this.directTitleFieldTarget.value, this.directURLFieldTarget.value, this.directOverviewFieldTarget.value)
    }

    searchDirectName() {
        this.organizationNameSearch(this.directTitleFieldTarget.value)
    }

    createOrganizationGoogleSheet() {
        this.createOrganizationManually(this.googleSheetNameTarget.innerHTML, this.googleSheetUrlTarget.innerHTML)
    }

    createOrganizationManually(name, website, content) {
        let url = "/admin/organization_import_utilities/create_organization_manually"
        let data = { name: name, website: website, content: content}

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(out => {
                this.organizationNameSearch(out.name.substring(0,8))
                M.toast({html:out.message, displayLength:5000})
            })
    }

    populateLocation(event) {
        this.clearDisplayData()

        this.googleSheetNameTarget.innerHTML = "<span style='color: #d5d4d4'>Loading...</span>";
        localStorage.setItem("rowID", this.rowIDTarget.value)
        let url = "/admin/organization_import_utilities/google_sheet/" + this.rowIDTarget.value
        fetch(url)
            .then(res => res.json())
            .then(out => {
                this.fieldTarget.value = out['name'];
                this.googleSheetNameTarget.innerHTML = out['name'];
                this.googleSheetUrlTarget.innerHTML = out['url'];
                localStorage.setItem("googleSheetName", out['name'])
                localStorage.setItem("googleSheetUrl", out['url'])
                this.organizationNameSearch(out['name'].substring(0,8))
            })
    }

    populateNextLocation(event) {
        var nextValue = parseInt(this.rowIDTarget.value ) + 1
        localStorage.setItem("rowID", nextValue)
        this.rowIDTarget.value = nextValue
        this.populateLocation()
    }

    populatePreviousLocation(event) {
        var nextValue = parseInt(this.rowIDTarget.value ) - 1
        localStorage.setItem("rowID", nextValue)
        this.rowIDTarget.value = nextValue
        this.populateLocation()
    }

    deleteGoogleSheetRow(event) {
        this.clearDisplayData()
        let url = "/admin/organization_import_utilities/google_sheet/" + this.rowIDTarget.value
        fetch(url, {
            method: 'DELETE',
        })
            .then(res => res.json())
            .then(out => {
                M.toast({html:out.message, displayLength:4000})
            })
    }

    organizationNameSearch(name) {
        let url = "/admin/organization_import_utilities/organization_name_search/" + name + "?site=" + window.location.hostname
        fetch(url, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(out => {
                console.log(out)
                var output = ""

                for (let organization of out) {
                    output = output.concat("<tr><td><a href='/admin/organizations/"+ organization.id + "/edit'>" + organization.name + "</a></td></tr>")
                }

                if (!output) {
                    output = "No matching organizations"
                }

                this.existingOrganizationsTarget.innerHTML = output;
            })
    }

    organizationSearch() {
        this.organizationNameSearch(this.organizationSearchFieldTarget.value)
    }

    updateRowData(event) {
        let url = "/admin/organization_import_utilities/google_sheet/" + this.rowIDTarget.value
        let data = { row: this.rowIDTarget.value, added: event.currentTarget.dataset.yn}
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(out => {
                M.toast({html:out.message, displayLength:4000})
                this.populateNextLocation()
            })
    }

    copyURL(event) {
        navigator.clipboard.writeText(this.googleSheetNameTarget.innerHTML)
        navigator.clipboard.writeText(this.googleSheetUrlTarget.innerHTML)
        M.toast({html: 'Copied Name & URL to clipboard', displayLength:4000})
    }

    keydown(event) {
        if (event.key == "Enter") {
            event.preventDefault()
        }
    }
}