$(document).ready(function () {
    $(".linkable").click(function(){
        // window.location = $(this).attr("data-href");
        window.open($(this).attr('data-href'), $(this).attr('data-target'));
        return false;
    });

    $(".linkable-news-feed-item").click(function(){
        window.open($(this).attr('data-href'), $(this).attr('data-target'));
        return false;
    });

    $(".linkable-nexus-item").click(function(){
        window.open($(this).attr('data-href'), $(this).attr('data-target'));
        return false;
    });
});