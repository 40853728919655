import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["map"]
    static values = { latitude: Number, longitude: Number, zoom: Number }

    connect() {
        if (typeof (google) != "undefined") {
            this.initMap()
        }
    }

    initMap() {
        this.map = new google.maps.Map(this.mapTarget, {
            center: new google.maps.LatLng(this.latitudeValue || 39.5, this.longitudeValue || -98.35),
            zoom: (this.zoomValue),
            controlSize: 24
        })

        this.marker = new google.maps.Marker({
            map: this.map,
            anchorPoint: new google.maps.Point(0, -29)
        })

        this.marker.setPosition({ lat: parseFloat(this.latitudeValue), lng: parseFloat(this.longitudeValue) })
        this.marker.setVisible(true)
    }
}