import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "field", "map", "latitude", "longitude", "zoomLevel" ]
    static values = { address: String }

    connect() {
        if (typeof(google) != "undefined") {
            this.initMap()
        }
    }

    initMap() {
        console.log("MAP INIT")
        console.log('ZL: ' + this.data.get("zoomLevel"))

        this.map = new google.maps.Map(this.mapTarget, {
            center: new google.maps.LatLng(this.data.get("latitude") || 39.5, this.data.get("longitude") || -98.35),
            zoom: (parseInt(this.data.get("zoomLevel")))
        })
        this.map.addListener('zoom_changed', this.zoomChanged.bind(this))

        this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
        this.autocomplete.bindTo('bounds', this.map)
        this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name', 'utc_offset_minutes', 'website', 'place_id', 'formatted_phone_number', 'business_status'])
        this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))

        this.map.addListener('dragend', this.updateLatLng.bind(this))
        this.map.addListener('zoom_changed', this.zoomChanged.bind(this))

        this.marker = new google.maps.Marker({
            map: this.map,
            anchorPoint: new google.maps.Point(0, -29)
        })

        this.marker.setPosition({ lat: parseFloat(this.latitudeTarget.value), lng: parseFloat(this.longitudeTarget.value) })
        this.marker.setVisible(true)
    }

    zoomLevelChange(event) {
        this.map.setZoom(parseInt(event.target.value))
    }

    zoomChanged() {
        this.zoomLevelTarget.value = this.map.zoom
    }

    updateLatLng() {
        var center = this.map.getCenter();
        var latitude = center.lat();
        var longitude = center.lng();

        this.latitudeTarget.value = latitude
        this.longitudeTarget.value = longitude

        this.marker.setPosition({ lat: parseFloat(this.latitudeTarget.value), lng: parseFloat(this.longitudeTarget.value) })
    }

    placeChanged() {
        let place = this.autocomplete.getPlace()

        // , 'utc_offset', 'website', 'place_id', 'formatted_phone_number', 'business_status'

        if (!place.geometry) {
            window.alert(`No details available for input: ${place.name}`)
            return
        } else  {
            console.log(`${JSON.stringify(place, null, 4)}`)
        }

        if (place.geometry.viewport) {
            this.map.fitBounds(place.geometry.viewport)
        } else {
            this.map.setCenter(place.geometry.location)
            this.map.setZoom(6)
        }

        this.marker.setPosition(place.geometry.location)
        this.marker.setVisible(true)

        this.latitudeTarget.value = place.geometry.location.lat()
        this.longitudeTarget.value = place.geometry.location.lng()

        var ev = new Event('change');
        this.latitudeTarget.dispatchEvent(ev)
        this.longitudeTarget.dispatchEvent(ev)
    }

    keydown(event) {
        if (event.key == "Enter") {
            event.preventDefault()
        }
    }

    fill_address(event) {
        event.preventDefault()
        this.fieldTarget.value = this.addressValue
    }
}