import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "newsletterID"
    ]

    testNewsletter(event) {
        let url = "/admin/newsletters/export_to_sendgrid/" + this.newsletterIDTarget.value
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify()
        })
            .then(res => res.json())
            .then(out => {
                M.toast({html:out.message, displayLength:5000})
            })
    }

    publishNewsletter(event) {
        console.log("Publish newsletter to subscribers")
        let url = "/admin/newsletters/publish_to_sendgrid/" + this.newsletterIDTarget.value
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify()
        })
            .then(res => res.json())
            .then(out => {
                M.toast({html:out.message, displayLength:5000})
            })
    }

    populateLocation(event) {
        this.clearDisplayData()

        this.googleSheetNameTarget.innerHTML = "<span style='color: #d5d4d4'>Loading...</span>";
        localStorage.setItem("rowID", this.rowIDTarget.value)
        let url = "/admin/organization_import_utilities/google_sheet/" + this.rowIDTarget.value
        fetch(url)
            .then(res => res.json())
            .then(out => {
                this.fieldTarget.value = out['name'];
                this.googleSheetNameTarget.innerHTML = out['name'];
                this.googleSheetUrlTarget.innerHTML = out['url'];
                localStorage.setItem("googleSheetName", out['name'])
                localStorage.setItem("googleSheetUrl", out['url'])
                this.organizationNameSearch(out['name'].substring(0,8))
            })
    }

    populateNextLocation(event) {
        var nextValue = parseInt(this.rowIDTarget.value ) + 1
        localStorage.setItem("rowID", nextValue)
        this.rowIDTarget.value = nextValue
        this.populateLocation()
    }

    populatePreviousLocation(event) {
        var nextValue = parseInt(this.rowIDTarget.value ) - 1
        localStorage.setItem("rowID", nextValue)
        this.rowIDTarget.value = nextValue
        this.populateLocation()
    }

    deleteGoogleSheetRow(event) {
        this.clearDisplayData()
        let url = "/admin/organization_import_utilities/google_sheet/" + this.rowIDTarget.value
        fetch(url, {
            method: 'DELETE',
        })
            .then(res => res.json())
            .then(out => {
                M.toast({html:out.message, displayLength:4000})
            })
    }

    organizationNameSearch(name) {
        let url = "/admin/organization_import_utilities/organization_name_search/" + name
        fetch(url, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(out => {
                console.log(out)
                var output = ""

                for (let organization of out) {
                    output = output.concat("<tr><td><a href='/admin/organizations/"+ organization.id + "/edit'>" + organization.display_name + "</a></td></tr>")
                }

                if (!output) {
                    output = "No matching organizations"
                }

                this.existingOrganizationsTarget.innerHTML = output;
            })
    }

    organizationSearch() {
        this.organizationNameSearch(this.organizationSearchFieldTarget.value)
    }

    updateRowData(event) {
        let url = "/admin/organization_import_utilities/google_sheet/" + this.rowIDTarget.value
        let data = { row: this.rowIDTarget.value, added: event.currentTarget.dataset.yn}
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(out => {
                M.toast({html:out.message, displayLength:4000})
                this.populateNextLocation()
            })
    }

    copyURL(event) {
        navigator.clipboard.writeText(this.googleSheetNameTarget.innerHTML)
        navigator.clipboard.writeText(this.googleSheetUrlTarget.innerHTML)
        M.toast({html: 'Copied Name & URL to clipboard', displayLength:4000})
    }

    keydown(event) {
        if (event.key == "Enter") {
            event.preventDefault()
        }
    }
}