import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "map", "latitude", "longitude", "zoomLevel" ]
    static values = { geojsonurl: String }

    connect() {
        if (typeof(google) != "undefined") {
            this.initMap()
        }
    }

    initMap() {
        this.map = new google.maps.Map(this.mapTarget, {
            center: new google.maps.LatLng(parseFloat(this.latitudeTarget.value) || 39.5, this.longitudeTarget.value || -98.35),
            zoom: parseInt(this.zoomLevelTarget.value)
        })

        this.marker = new google.maps.Marker({
            map: this.map,
            anchorPoint: new google.maps.Point(0, -29)
        })

        this.map.data.setStyle((feature) => {
            let fillColor = "gray"
            let fillOpacity = 0.0
            let strokeColor = "red"
            let strokeWeight = 1.5;
            let strokeOpacity = 0.6

            if (feature.getProperty("stroke")) {
                strokeColor = feature.getProperty("stroke");
            }

            if (feature.getProperty("stroke-width")) {
                strokeWeight = feature.getProperty("stroke-width");
            }

            if (feature.getProperty("stroke-opacity")) {
                strokeOpacity = feature.getProperty("stroke-opacity");
            }

            if (feature.getProperty("fill-color")) {
                fillColor = feature.getProperty("fill-color");
            }

            if (feature.getProperty("fill-opacity")) {
                fillOpacity = feature.getProperty("fill-opacity");
            }

            return /** @type {!google.maps.Data.StyleOptions} */ {
                fillColor: fillColor,
                fillOpacity: fillOpacity,
                strokeColor: strokeColor,
                strokeWeight: strokeWeight,
                strokeOpacity: strokeOpacity
            };
        });

        this.map.data.loadGeoJson(this.geojsonurlValue);

        this.map.addListener('dragend', this.updateLatLng.bind(this))
        this.map.addListener('zoom_changed', this.zoomChanged.bind(this))

        this.marker.setPosition({ lat: parseFloat(this.latitudeTarget.value), lng: parseFloat(this.longitudeTarget.value) })
        this.marker.setVisible(true)
    }

    updateLatLng() {
        var center = this.map.getCenter();
        var latitude = center.lat();
        var longitude = center.lng();

        this.latitudeTarget.value = latitude
        this.longitudeTarget.value = longitude

        this.marker.setPosition({ lat: parseFloat(this.latitudeTarget.value), lng: parseFloat(this.longitudeTarget.value) })
    }

    zoomChanged() {
        this.zoomLevelTarget.value = this.map.zoom
    }

    keydown(event) {
        if (event.key == "Enter") {
            event.preventDefault()
        }
    }
}