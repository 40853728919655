import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
    static targets = [
        "existingNewsFeedItems",
        "newsFeedItemSearchField",
        "directTitleField",
        "directURLField",
        "directContentField",
        "existingLexiconEntries",
        "lexiconEntrySearchField",
        "lexiconEntryNameField",
        "lexiconEntryAcronymField",
        "lexiconEntryURLField",
        "lexiconEntryDescriptionField"
    ]
    connect() {

    }
    createNewsFeedItemDirect() {
        this.createNewsFeedItem(this.directTitleFieldTarget.value, this.directURLFieldTarget.value, this.directContentFieldTarget.value)
    }

    createNewsFeedItem(name, website, content) {
        let url = "/admin/direct_import_utilities/create_news_feed_item"
        let data = { name: name, website: website, content: content}

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(out => {
                this.newsFeedItemTitleSearch(out.name.substring(0,8))
                M.toast({html:out.message, displayLength:5000})
            })
    }

    searchDirectTitle() {
        this.newsFeedItemTitleSearch(this.directTitleFieldTarget.value)
    }

    searchNewsFeedItemSearchField() {
        this.newsFeedItemTitleSearch(this.newsFeedItemSearchFieldTarget.value)
    }

    newsFeedItemTitleSearch(name) {
        let url = "/admin/direct_import_utilities/news_feed_item_title_search/" + name
        fetch(url, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(out => {
                console.log(out)
                var output = ""

                for (let news_feed_item of out) {
                    output = output.concat("<tr><td><a href='/admin/news_feed_items/"+ news_feed_item.id + "/edit'>" + news_feed_item.title + "</a></td></tr>")
                }

                if (!output) {
                    output = "No matching News Feed Items"
                }

                this.existingNewsFeedItemsTarget.innerHTML = output;
            })
    }




    createLexiconEntryDirect() {
        this.createLexiconEntry(this.lexiconEntryNameFieldTarget.value, this.lexiconEntryAcronymFieldTarget.value, this.lexiconEntryURLFieldTarget.value, this.lexiconEntryDescriptionFieldTarget.value)
    }

    createLexiconEntry(name, acronym, website, description) {
        let url = "/admin/direct_import_utilities/create_lexicon_entry"
        let data = { name: name, acronym: acronym, website: website, description: description}

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(out => {
                this.lexiconEntryNameSearch(out.name.substring(0,8))
                M.toast({html:out.message, displayLength:5000})
            })
    }

    searchLexiconEntryName() {
        this.lexiconEntryNameSearch(this.lexiconEntryNameFieldTarget.value)
    }

    searchLexiconEntrySearchField() {
        this.lexiconEntryNameSearch(this.lexiconEntrySearchFieldTarget.value)
    }

    lexiconEntryNameSearch(name) {
        let url = "/admin/direct_import_utilities/lexicon_entry_name_search/" + name
        fetch(url, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(out => {
                console.log(out)
                var output = ""

                for (let lexicon_entry of out) {
                    output = output.concat("<tr><td><a href='/admin/lexicon_entries/"+ lexicon_entry.id + "/edit'>" + lexicon_entry.name + "</a></td></tr>")
                }

                if (!output) {
                    output = "No matching Lexicon Entries"
                }

                this.existingLexiconEntriesTarget.innerHTML = output;
            })
    }

    keydown(event) {
        if (event.key == "Enter") {
            event.preventDefault()
        }
    }
}