import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "url"
    ]

    scrapeWebpage() {
        if (this.urlTarget.value) {
            let url = "/admin/scraping_utilities/scrape_webpage"
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 'url': this.urlTarget.value })
            })
                .then(res => res.json())
                .then(out => {
                    console.log(out)
                    M.toast({html:out.message, displayLength:4000})
                })
        } else {
            window.alert('Please provide a URL')
        }
    }
}